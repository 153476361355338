<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>love-heart-hands-hold</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M20.25.886l2.014,2.381a4.5,4.5,0,0,1,.986,2.812V11.25a1.5,1.5,0,0,1-1.5,1.5h0a1.5,1.5,0,0,1-1.5-1.5V7.5"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.75.886v3a4.493,4.493,0,0,0,.757,2.5L17.649,8.1a1.4,1.4,0,0,0,1.977.445h0A1.4,1.4,0,0,0,20.1,6.75L18.75,4.5"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M3.75.886,1.736,3.267A4.5,4.5,0,0,0,.75,6.079V11.25a1.5,1.5,0,0,0,1.5,1.5h0a1.5,1.5,0,0,0,1.5-1.5V7.5"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M8.25.886v3a4.493,4.493,0,0,1-.757,2.5L6.351,8.1a1.4,1.4,0,0,1-1.977.445h0A1.4,1.4,0,0,1,3.9,6.75L5.25,4.5"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12,23.25,6.886,17.915a3.025,3.025,0,0,1-.567-3.492h0a3.026,3.026,0,0,1,4.846-.786l.835.835.835-.835a3.026,3.026,0,0,1,4.846.786h0a3.025,3.025,0,0,1-.567,3.492Z"
    />
  </svg>
</template>
